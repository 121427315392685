import * as React from "react"
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import PageBuilder from "../builder/page-builder"
import Hero from "../builder/hero"

const Page = ({ data }) => {
    
    return (
        <Layout>
            <main>
                {data.page.nodes.map(page => (
                    <>
                        <HelmetDatoCms 
                            seo={page.seoMetaTags}
                        />
                        <style>
                            {`body {background-color: ${page.backgroundColour.hex};}`}
                        </style>
                        <Hero data={page} />
                        <PageBuilder data={page.content} />
                    </>
                ))}
            </main>
        </Layout>
    )
}

export default Page

export const query = graphql`
    query
    (
    $id: String!) 
    {
        page: allDatoCmsPage(filter: {id: {eq: $id}}) {
            nodes {
                title
                slug
                heroSize
                backgroundColour {
                    hex
                    red
                    green
                    blue
                }
                heroBackground {
                    ... on DatoCmsBackgroundImage {
                        __typename
                        scrim
                        imageBackground {
                            url(imgixParams: {w: "1920"})
                        }
                        imageForeground {
                            url(imgixParams: {w: "1080"})
                        }
                    }
                    ... on DatoCmsBackgroundVideo {
                        __typename
                        scrim
                        link
                        linkMobile
                        videoPoster {
                            url(imgixParams: {w: "1440"})
                        }
                    }
                }
                heroContent {
                    blocks {
                        ... on DatoCmsIconContent {
                            ...IconContent
                        }
                        ... on DatoCmsLogo {
                            ...LogoContent
                        }
                    }
                    links
                    value
                }
                heroBottomItems {
                    id
                    icon
                    url
                    text
                }
                ...PageContent
                seoMetaTags {
                    ...GatsbyDatoCmsSeoMetaTags
                }
            }
        }
}
`;